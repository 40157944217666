import { FOOTER_LINKS } from '@/constants/strings'

import CopyRight from './CopyRight'
import KisanApp from './KisanApp'
import LinkList from './LinkList'
import Social from './social'

const Footer = () => {
  return (
    <footer className="py-11 bg-neutral-10 ">
      <section className="max-w-7xl px-6 m-auto pb-12 flex sm:flex-row flex-col flex-wrap">
        <KisanApp />
        {FOOTER_LINKS.map((item) => (
          <LinkList key={item.header} {...item} />
        ))}
        <Social />
      </section>
      <CopyRight />
    </footer>
  )
}

export default Footer
