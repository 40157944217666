import Link from 'next/link'
const currentYear = new Date().getFullYear() // This gets the current year

interface Props {
  href: string
  label: string
  isExternal?: boolean
}

const CopyRightLinkItem = ({ href, label, isExternal = false }: Props) => (
  <Link
    href={href}
    target={isExternal ? '_blank' : undefined}
    rel={isExternal ? 'noreferrer' : undefined}
    className="hover:text-primary-100 hover:underline transition-all border-r-2 border-r-neutral-70 inline-block px-2.5"
  >
    {label}
  </Link>
)

const CopyRight = () => (
  <footer className="border-t border-neutral-30 pt-6 px-12 max-w-7xl m-auto flex flex-wrap justify-center">
    <span className="border-r-2 border-r-neutral-70 inline-block px-2.5">
      Copyright @DeHaat {currentYear} {/* Use the currentYear variable here */}
    </span>
    <CopyRightLinkItem
      href="https://policies.agrevolution.in/privacy-policy/"
      label="Privacy Policy"
      isExternal
    />
    <CopyRightLinkItem
      href="https://policies.agrevolution.in/terms-and-conditions/"
      label="Terms & Condition"
      isExternal
    />
    <CopyRightLinkItem
      href="/responsible-disclosure"
      label="Responsible Disclosure"
    />
    <span className="inline-block px-2.5">All rights reserved</span>
  </footer>
)

export default CopyRight
