import { forwardRef, Ref } from 'react'

interface Props {
  pathClassName: string
  height: number
  width: number
}

const FacebookFilled = forwardRef(
  ({ pathClassName, height, width }: Props, ref: Ref<SVGSVGElement>) => (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      ref={ref}
    >
      <circle cx="20" cy="20" r="20" fill="#3B5998" />
      <path
        d="m24 21.17.5-3.25h-3.1v-2.14c0-.92.46-1.78 1.84-1.78h1.42v-2.8s-1.27-.2-2.54-.2c-2.6 0-4.27 1.58-4.27 4.37v2.5H15v3.25h2.85V29h3.5v-7.83H24Z"
        className={pathClassName}
      />
    </svg>
  )
)

export default FacebookFilled
