import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const Youtube = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 23 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path d="M22.86 5.02c0-2.8-2.23-5.02-5.02-5.02H5.02A4.99 4.99 0 0 0 0 5.02v5.96C0 13.78 2.22 16 5.02 16h12.82c2.8 0 5.02-2.22 5.02-5.02V5.02ZM15.3 8.44 9.6 11.3c-.26.13-1.02-.06-1.02-.32V5.14c0-.25.76-.44 1.02-.31L15.1 7.8c.2.19.45.5.2.63Z" />
  </svg>
))

export default Youtube
