import Twitter from '@/components/svg/Twitter'
import STRINGS from '@/constants/strings'

import FacebookFilled from '../svg/FacebookFilled'
import Instagram from '../svg/Instagram'
import LinkedinBlue from '../svg/LinkedinBlue'
import Message from '../svg/Message'
import Youtube from '../svg/Youtube'

const links = [
  {
    title: "Link to Dehaat's twitter account",
    url: 'https://twitter.com/DeHaatTM?s=09',
    icon: <Twitter fill="#1DA1F2" height="20" width="20" />,
  },
  {
    title: "Link to Dehaat's facebook account",
    url: 'https://www.facebook.com/DeHaatTM/',
    icon: (
      <FacebookFilled pathClassName="fill-neutral-20" height={40} width={40} />
    ),
  },
  {
    title: "Link to Dehaat's Instagram account",
    url: 'https://www.instagram.com/dehaat/?hl=en',
    icon: <Instagram height={18} width={18} fill="#8A3AB9" />,
  },
  {
    title: "Link to Dehaat's linkedin account",
    url: 'https://www.linkedin.com/company/dehaat',
    icon: <LinkedinBlue />,
  },
  {
    title: "Link to Dehaat's Youtube account",
    url: 'https://www.youtube.com/@DeHaat/featured',
    icon: <Youtube width={23} height={16} fill="red" />,
  },
]

const Social = () => {
  return (
    <section className="lg:pl-6 lg:w-1/4 lg:mt-0 mt-8">
      <p className="text-neutral-90 text-xl leading-[27px] font-natosans-bold">
        {STRINGS.COMMON.CONNECT_WITH_US}
      </p>
      <article className="space-x-4 flex my-6">
        {links.map(({ title, url, icon }) => (
          <a
            href={url}
            target="_blank"
            aria-label={title}
            rel="noreferrer"
            className="h-10 w-10 bg-neutral-20 rounded-full flex items-center justify-center hover:scale-110 transition-all"
            key={url}
          >
            {icon}
          </a>
        ))}
      </article>
      <a
        href="mailto:contact@agrevolution.in"
        className="flex items-center hover:text-primary-100 hover:underline underline-offset-8 transition-all"
      >
        <Message width={23} height={16} className="fill-neutral-90" />
        <span className="pl-4">contact@agrevolution.in</span>
      </a>
    </section>
  )
}

export default Social
