import { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLImageElement> {
  image: string
  imageAlt: string
  pictureStyle?: string
  imageStyle?: string
  loading?: 'lazy' | 'eager'
  height?: number | string
  width?: number | string
  hasPng?: boolean
}

const Picture = ({
  image,
  imageAlt,
  pictureStyle,
  imageStyle,
  loading = 'lazy',
  height,
  width,
  hasPng = false,
  ...imageProps
}: Props) => (
  <picture className={pictureStyle}>
    <source srcSet={`${image}.webp`} type="image/webp" />
    {hasPng ? (
      <source srcSet={`${image}.png`} type="image/png" />
    ) : (
      <source srcSet={`${image}.jpg`} type="image/jpeg" />
    )}
    <img
      src={`${image}.${hasPng ? 'png' : 'jpg'}`}
      alt={imageAlt}
      className={imageStyle}
      loading={loading}
      height={height}
      width={width}
      {...imageProps}
    />
  </picture>
)

export default Picture
