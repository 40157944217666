import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const Message = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 23 16"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path d="M11.64 8.65 21.5.49c-.4-.32-.9-.5-1.42-.49H2.32A2.3 2.3 0 0 0 .84.54l9.94 8.12a.72.72 0 0 0 .86-.01Z" />
    <path d="M2.32 16h17.76a2.32 2.32 0 0 0 2.32-2.32V2.32c0-.16-.02-.31-.05-.46L12.64 9.9a2.35 2.35 0 0 1-2.86.02L.03 1.94c-.02.12-.03.25-.03.38v11.36A2.32 2.32 0 0 0 2.32 16Z" />
  </svg>
))

export default Message
