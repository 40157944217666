import Picture from '../picture'
import DeHaatLogoFooter from '../svg/DeHaatLogoFoooter'

const KisanApp = () => (
  <section className="text-neutral-90 lg:w-1/4 sm:w-1/3">
    <DeHaatLogoFooter className="fill-primary-100 h-14 w-[155px] md:h-[70px] md:w-[193.75px]" />
    <a
      className="block md:mt-10 mt-[25px] font-natosans-semibold text-base md:text-lg leading-[22px] md:leading-[25px] hover:text-primary-100 hover:underline underline-offset-8 transition-all"
      href="https://play.google.com/store/apps/details?id=app.intspvt.com.farmer"
      target="_blank"
      rel="noreferrer"
    >
      Download Farmer App
    </a>
    <Picture
      image={'/assets/images/kisan-app-qrcode'}
      imageAlt={'Scan to download Kisan app'}
      className="aspect-square h-[172px] md:h-[200px] mt-2 md:mt-4"
    />
    <p className="mt-4 md:mt-6 font-natosans-semibold text-base md:text-lg leading-22 md:leading-[25px]">
      Or{' '}
      <a
        href="https://kisan-app.agrevolution.in"
        target="_blank"
        rel="noreferrer"
        className="hover:text-primary-100 hover:underline underline-offset-8 transition-all"
      >
        Visit our Kisaan Website
      </a>
    </p>
  </section>
)

export default KisanApp
