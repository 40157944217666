import type { NextPage } from 'next'
import Head from 'next/head'
import Link from 'next/link'

import Footer from '@/components/footer'
import Header from '@/components/navigation'

const Home: NextPage = () => {
  return (
    <div className="p-4 px-8">
      <Head>
        <title>
          DeHaat, From Seeds to Market | Online marketplace for farmers
        </title>
        <meta
          name="description"
          content="DeHaat connects farmers to suppliers and buyers on a single platform. Online marketplace providing all the agricultural products and services to farmers."
        />
      </Head>
      <main className="max-w-7xl m-auto">
        <Header />
        <section className="mt-4 h-full w-full flex justify-center items-center">
          <Link
            href="/"
            aria-label="Go back home"
            className="block h-full w-full aspect-video"
          >
            <img
              src="/assets/images/404.jpeg"
              alt="Page not found"
              className="h-full w-full"
            />
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  )
}

export default Home
