import { forwardRef, Ref } from 'react'

interface Props {
  height?: number
  width?: number
}

const LinkedinBlue = forwardRef(
  ({ height = 18, width = 19 }: Props, ref: Ref<SVGSVGElement>) => (
    <svg
      viewBox="0 0 19 18"
      fill="#0077B5"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      ref={ref}
    >
      <path d="M9.95 7.62c.24-.3.43-.58.72-.83a3.96 3.96 0 0 1 2.82-1.11c.58 0 1.21.05 1.74.2a3.58 3.58 0 0 1 2.48 2.56c.29.97.34 1.94.34 2.96v6.3c0 .2-.05.25-.24.25h-3.25c-.2 0-.25-.05-.25-.24v-5.97c0-.48-.05-1.02-.2-1.5a1.7 1.7 0 0 0-1.83-1.31c-1.27.05-1.95.68-2.14 1.99-.05.29-.05.63-.05.92v5.82c0 .2-.05.24-.24.24H6.6c-.2 0-.24-.05-.24-.24V6.11c0-.2.04-.24.24-.24h3.1c.2 0 .25.05.25.24v1.5ZM4.03 11.98v5.73c0 .2-.05.29-.3.29H.5c-.2 0-.25-.05-.25-.24V6.2c0-.15.05-.2.2-.2h3.3c.19 0 .24.1.24.25.05 1.9.05 3.83.05 5.72ZM4.32 2.18c0 1.22-.97 2.19-2.19 2.19A2.23 2.23 0 0 1 0 2.18C0 .97.97 0 2.18 0c1.17 0 2.14.97 2.14 2.18Z" />
    </svg>
  )
)

export default LinkedinBlue
