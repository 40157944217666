import Link from 'next/link'

interface Props {
  label: string
  link: string
  external?: boolean
}

const LinkItem = ({ label, link, external = false }: Props) => {
  const className =
    'text-neutral-80 font-natosans-medium text-base leading-[22px] hover:text-primary-100 hover:underline underline-offset-8 transition-all'

  return external ? (
    <a href={link} target="_blank" rel="noreferrer" className={className}>
      {label}
    </a>
  ) : (
    <Link href={link} className={className}>
      {label}
    </Link>
  )
}

export default LinkItem
