import LinkItemComponent from './LinkItem'

interface LinkItem {
  label: string
  link: string
  external?: boolean
}

interface Props {
  header: string
  links: LinkItem[]
}

const LinkList = ({ header, links }: Props) => {
  return (
    <article className="sm:pl-6 lg:w-1/4 sm:w-1/3 sm:mt-0 mt-8">
      <p className="text-neutral-90 font-natosans-bold text-xl">{header}</p>
      <ul className="space-y-4 mt-5">
        {links.map((link) => (
          <li key={link.link}>
            <LinkItemComponent {...link} />
          </li>
        ))}
      </ul>
    </article>
  )
}

export default LinkList
